<template>
    <div style="width: 100%;height: 360px;font-size:12px;">
        <div style="display: flex;">
            <div style="width: 360px;line-height: 30px;text-align: center; ">
                支付金额:<span style="color: #FFA133;font-weight: bold;font-size: 14px;">￥{{price}}</span>
            </div>
            <div @click="onClose" style="width: 20px;height: 20px;">
                <img style="width: 20px;height: 20px;cursor: pointer;padding-left: 5px;" src="@/assets/imgs/other/gb.png">
            </div>
        </div>
        <div v-if="isShowError == false">
            <div style="width: 100%;line-height: 30px;text-align: center;">支付剩余时间:<count-down :endTime="endTime" :endText="endText" @change="getWxPayCode" /></div>
            <div style="width: 100%;height: 300px;text-align: center;">
                <vue-qr
                    :text="qrImgUrl"
                    :size="290"
                    :logoScale="0.2">
                </vue-qr>
            </div>
        </div>
        <div v-if="isShowError == true" style="width: 100%;height: 300px;text-align: center;">
            <div class="no-data-container">
                <el-empty description="暂无数据"></el-empty>
            </div>
        </div>
    </div>

</template>

<script>
import VueQr from "vue-qr";
import countDown from "@/components/common/countDown";
import {number} from "echarts";
export default {
    name: "wxPayCode",
    components:{
        VueQr,
        countDown
    },
    data(){
        return {
            qrImgUrl:"",
            endTime:'',//  console.log(endTime)=>得到毫秒  1658030420 可在方法里面放入后台数据,new Date('放后台数据').getTime() / 1000 +  ' ',但要记得调用方法
            endText:'二维码获取中,请稍后',
            msg:"请完成当前订单!",
            orderNo:"",
            isShowError:false,
            sign:this.$route.query.sign,
        }
    },
    props:{
        price:{
            type:String,
            default() {
                return '0'
            }
        },
        orderType:{
            type:String,
            default() {
                return ""
            }
        },
        onClose:{
            type:Function,
            default(){
                return function (){}
            }
        },
        payParams:{
            type:String,
            default(){
                return ""
            }
        }
    },
    mounted() {
        // this.checkWxPayCode();
        var that = this;
        console.log(that.price, that.orderType);
        if(that.price == '0' || that.orderType == ""){
            that.utils.err("微信支付参数错误");
            that.isShowError = true;
            return false;
        }
        this.getWxPayCode();
    },
    methods:{
        //检查没过期且已生成的微信二维码
        checkWxPayCode(){
            var that = this;
            that.newApi.checkWxPayCode({orderType:that.orderType}).then((res)=>{
                if(res.data.status == 100){
                    that.qrImgUrl = res.data.codeUrl;
                    that.endTime = res.data.endTime + '';
                    that.orderNo = res.data.orderNo;
                }else{
                    that.getWxPayCode();
                }
            })
        },

        //生成微信二维码接口
        getWxPayCode() {
            var that = this;
            if(that.price == "0" || that.orderType == ""){
                return that.utils.err("参数错误!");
            }
            if(parseInt(that.orderType) == 9 || parseInt(that.orderType) == 10){
                if (that.payParams == "") {
                    return that.utils.err("支付参数错误!");
                }
            }
            var params = {};
            params.price = that.utils.keepTwoDecimalFull(that.price);
            params.orderType = that.orderType;
            params.payParams = JSON.stringify(that.payParams);
            that.newApi.getWxPayCode(params).then((res) => {
                if(res.isSuccess == 1){
                    that.qrImgUrl = res.data.codeUrl;
                    that.endTime = res.data.endTime + '';
                    that.orderNo = res.data.orderNo;
                    params.orderNo = res.data.orderNo;

                    //保存修改的预支付订单信息
                    that.newApi.addAndSavePrePayOrder({paymentType:3,type:2,toPay:JSON.stringify(params),sign:that.sign}).then(() => { })
                }else{
                    that.isShowError = true;
                }
            })
        },

        // 生成二维码
        getCode(code, endTime) {
            this.qrImgUrl = code;
            this.endTime = endTime;
        },

        //取消订单
        closeWxOrder(){
            var that = this;
            that.utils.confirm("取消订单不可恢复，确认？",function () {
                that.newApi.closeWxOrder({orderNo:that.orderNo}).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.qrImgUrl = "";
                        that.endTime = "";
                        that.orderNo = "";
                        that.input = "";
                    }
                })
            })

        }
    }
}
</script>

<style scoped>
/deep/.el-dialog__header {
    padding: 0px !important;
}
</style>