<template>
    <div></div>

</template>

<script>
import {number} from "echarts";

export default {
    name: "aliPayCode",
    components:{
    },
    data(){
        return {
            orderNo:"",
            formStr:"",
            showMessage: true,
            sign:this.$route.query.sign,
        }
    },
    props:{
        price:{
            type:String,
            default() {
                return '0'
            }
        },
        orderType:{
            type:String,
            default() {
                return ""
            }
        },
        payParams:{
            type:String,
            default(){
                return ""
            }
        }

    },
    mounted() {
        // this.checkAliPayOrderNotpaidInfo();
        this.AliPay();
    },
    methods:{
        // 点击支付完成操作
        closePayBox() {
            this.showMessage = false;
        },
        checkAliPayOrderNotpaidInfo(){
            var that = this;
            that.newApi.checkAliPayOrderNotpaidInfo({orderType:that.orderType}).then((res)=>{
                if(res.data.status == 100){
                    that.utils.b('/aliCodeUrl/' + res.data.orderNo);
                }else{
                    that. AliPay();
                }
            })
        },
        AliPay(){
            var that = this;
            if(that.price == '0'|| that.orderType == ""){
                return that.utils.err("参数错误!");
            }
            if(parseInt(that.orderType) == 9 || parseInt(that.orderType) == 10){
                if (that.payParams == "") {
                    return that.utils.err("支付参数错误!");
                }
            }
            var params = {};
            params.price = that.utils.keepTwoDecimalFull(that.price);
            params.orderType = that.orderType;
            params.payParams = JSON.stringify(that.payParams);
            that.newApi.getAliPayCode(params).then((res) => {
                if(res.isSuccess == 1){
                    params.orderNo = res.data.orderNo;
                    //保存修改的预支付订单信息
                    that.newApi.addAndSavePrePayOrder({paymentType:4,type:2,toPay:JSON.stringify(params),sign:that.sign}).then(() => { })

                    that.utils.b('/aliCodeUrl/' + res.data.orderNo);
                }

            })
        },
    }
}
</script>

<style scoped>

</style>