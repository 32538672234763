<template>
    <div class="full-screen-div">
        <div style="width: calc(100% - 2px); height: 50px; border: 1px solid #c1c1c1;background: #FFFFFF;">
            <div style="width: 130px; height: 35px; margin-top: 7.5px; margin-left: 24px;">
                <img style="width: 100%; height: 100%;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png">
            </div>
        </div>
        <div style="width: calc(100% - 2px); height: 50px; border: 1px solid #c1c1c1; border-top: none; display: flex; align-items: center;background: #FFFFFF;">
            <div style="width: 130px; line-height: 50px; margin-left: 28px;">
                支付
            </div>
            <div v-if="inPayment == 0" style="width: 430px; height: 50px;  margin: 0 auto;position: absolute;margin-left: calc(50% - 215px);">
                <div style="width: 430px;height: 28px;margin-top: 11px;background: #FFF3E0;">
                    <div style="width: 16px;height: 14px;float: left;margin-top: 4px;margin-left: 5px;">
                        <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/jg.png">
                    </div>
                    <div style="width:calc(100% - 26px);line-height: 23px;float: left;padding-left: 5px;">
                        <span style="font-size: 12px;">安全提示：支付前请检查您周围的支付环境，避免支付信息泄露</span>
                    </div>
                </div>
            </div>
        </div>
        <!--支付进行中-->
        <div v-if="inPayment == 0" style="width: 100%;height: 850px;background: #F1F0F4;">
            <!--支付订单显示-->
            <div style="width: 80%;height:176px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;height: auto;display: flex;border-bottom: 1px dashed #EBECEC;">
                    <div style="width: 97%;height: 48px;margin: 0 auto;">
                        <div style="width: 120px;line-height: 48px;font-size: 14px;padding-left: 10px;">待支付订单</div>
                    </div>
                    <div style="calc(100% - 120px);height: 48px;margin: 0 auto;">
                        <div style="width: 220px;line-height: 48px;font-size: 14px;text-align: right;padding-right: 10px;">
                            <span style="font-weight: bold;">应付：</span>
                            <span style="font-weight: bold;color: #FF8A00;">￥{{payParams.payAmt}}</span>
                        </div>
                    </div>
                </div>
                <div style="width:calc(100% - 20px); height: 86px;margin: 0 auto;overflow-x: auto;padding-top: 20px;">
                    <table>
                        <tr style="background: #F5F5F6">
                            <td v-if="orderNo != ''">订单号</td>
                            <td>产品</td>
                            <td>数量</td>
                            <td>下单时间</td>
                            <td style="text-align: right;padding-right: 10px;">总额</td>
                        </tr>
                        <tr>
                            <td v-if="orderNo != ''">{{orderNo}}</td>
                            <td>{{productName.note}}</td>
                            <td>1</td>
                            <td>{{productName.time}}</td>
                            <td style="text-align: right;padding-right: 10px;">{{payParams.payAmt}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <!--支付方式显示-->
            <div v-if="paramsSatus == 1" style="width: 80%;height:auto;max-height:315px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;height: auto;display: flex;border-bottom: 1px dashed #EBECEC;">
                    <div style="width: 100%;height: 48px;margin: 0 auto;">
                        <div style="width: 120px;line-height: 48px;font-size: 14px;padding-left: 10px;">请选择支付方式</div>
                    </div>
                </div>
                <div style="width:calc(100% - 20px); height: auto;max-height:285px;margin: 0 auto;overflow-x: auto;padding-top: 20px;">
                    <template>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="扫码支付" name="scanCode" >
                                <div style="text-align: center;">
                                    <div style="width: 160px;height: 160px;border: 1px solid #E4E7ED;background: #ECF4FB;margin: 0 auto;">
                                        <vue-qr
                                            :text="qrImgUrl + '?sign=' + sign"
                                            :size="160"
                                            :logoScale="0.2">
                                        </vue-qr>
                                    </div>
                                    <div style="width: 160px;line-height: 35px;display: flex;margin: 0 auto;">
                                        <div style="width: 20px;height: 20px;margin-right: 3px;">
                                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/weixin.png">
                                        </div>
                                        <div style="width: 20px;height: 20px;margin-right: 3px;">
                                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/alipay.png">
                                        </div>
                                        <div style="font-size: 12px;">微信/支付宝扫码支付</div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="银行卡支付" name="platform">
                                <div style="width: 100%;height:auto; float:left;">
                                    <div v-for="(item,index) in cardList" :key="index" :style="platformRadio == index ? 'background: #ECF4FB;' : ''" style="width: 278px;height: 68px;float:left;margin-right: 20px;margin-bottom: 10px;border: 1px solid #E4E7ED;">
                                        <div style="width: 190px;height: 15px;float: left;margin-top: 24.5px;padding-left: 10px;">
                                            <template >
                                                <el-radio v-model="platformRadio" :label="index">
                                                    <span style="color: #747A7B;font-size: 18px;">{{item.bankName}}</span>
                                                </el-radio>
                                            </template>
                                        </div>
                                        <div style="width: 68px;height: 50px;float: left;">
                                            <img style="width: 68px;height: 50px;margin-top: 10px;" :src="item.bankUrl">
                                        </div>
                                    </div>
                                    <!--添加银行卡-->
                                    <div @click="isShowBandCard = true" :style="addCardHover == true ? 'background:#ECF4FB;':'background:#FFFFFF;'" @mouseover="addCardHover = true" @mouseleave="addCardHover = false" style="width: 278px;height: 68px;float:left;margin-right: 20px;margin-bottom: 10px;border: 1px solid #E4E7ED;cursor: pointer;">
                                        <div style="width: 175px;line-height: 68px;float: left;padding-left: 35px;font-size: 18px;color: #747A7B;font-weight: bold;">
                                            添加银行卡
                                        </div>
                                        <div style="width: 68px;height: 50px;float: left;">
                                            <img style="width: 50px;height: 40px;margin-top: 12px;" src="@/assets/imgs/other/jh.png">
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="对公支付" name="corporate" v-if="user.userType == 2">
                                <div style="width: 100%;height: 100%;float:left;">
                                    <div v-if="corpCardInfo != null" :style="corporateRadio == corpCardInfo.id ? 'background: #ECF4FB;': ''" style="width: 278px;height: 68px;float:left;margin-right: 20px;margin-bottom: 10px;border: 1px solid #E4E7ED;">
                                        <div style="width: 190px;height: 15px;float: left;margin-top: 24.5px;padding-left: 10px;">
                                            <template >
                                                <el-radio v-model="corporateRadio" :label="corpCardInfo.id">
                                                    <span style="color: #747A7B;font-size: 18px;">中国{{corpCardInfo.bankName}}</span>
                                                </el-radio>
                                            </template>
                                        </div>
                                        <div style="width: 68px;height: 50px;float: left;">
                                            <img style="width: 68px;height: 50px;margin-top: 8px;" :src="corpCardInfo.bankUrl">
                                        </div>
                                    </div>
                                    <!--添加对公账户-->
                                    <div v-if="corpCardInfo == null" @click="isShowCorpCard = true" :style="addCorpCardHover == true ? 'background:#ECF4FB;':'background:#FFFFFF;'" @mouseover="addCorpCardHover = true" @mouseleave="addCorpCardHover = false" style="width: 278px;height: 68px;float:left;margin-right: 20px;margin-bottom: 10px;border: 1px solid #E4E7ED;cursor: pointer;">
                                        <div style="width: 175px;line-height: 68px;float: left;padding-left: 35px;font-size: 18px;color: #747A7B;font-weight: bold;">
                                            添加对公账户
                                        </div>
                                        <div style="width: 68px;height: 50px;float: left;">
                                            <img style="width: 50px;height: 40px;margin-top: 12px;" src="@/assets/imgs/other/jh.png">
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="微信支付" name="wechat" v-if="payParams.type == 1">
                                <div style="width: 100%;height: 100%;float:left;">
                                    <div style="width: 278px;height: 68px;float:left;margin-right: 20px;margin-bottom: 10px;border: 1px solid #E4E7ED;background: #ECF4FB;">
                                        <div style="width: 190px;height: 15px;float: left;margin-top: 24.5px;padding-left: 10px;">
                                            <template >
                                                <el-radio v-model="wxRadio" :label="wxRadio">
                                                    <span style="color: #747A7B;font-size: 18px;">微信支付</span>
                                                </el-radio>
                                            </template>
                                        </div>
                                        <div style="width: 68px;height: 50px;float: left;">
                                            <img style="width: 40px;height: 40px;margin-top: 8px;padding-top: 5px;padding-left: 15px;" src="@/assets/imgs/other/wechat.png">
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="支付宝支付" name="alipay" v-if="payParams.type == 1">
                                <div style="width: 100%;height: 100%;float:left;">
                                    <div style="width: 278px;height: 68px;float:left;margin-right: 20px;margin-bottom: 10px;border: 1px solid #E4E7ED;background: #ECF4FB;">
                                        <div style="width: 190px;height: 15px;float: left;margin-top: 24.5px;padding-left: 10px;">
                                            <template >
                                                <el-radio v-model="aliRadio" :label="aliRadio">
                                                    <span style="color: #747A7B;font-size: 18px;">支付宝支付</span>
                                                </el-radio>
                                            </template>
                                        </div>
                                        <div style="width: 68px;height: 50px;float: left;">
                                            <img style="width: 40px;height: 40px;margin-top: 8px;padding-top: 5px;padding-left: 15px;" src="@/assets/imgs/other/alipay.png">
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </template>
                </div>
            </div>
            <!--支付表单-->
            <div v-if="form == true && paramsSatus == 1" style="width: 80%;height:146px;margin: 0 auto;background: #FFFFFF;margin-top: 5px;">
                <div style="width: 100%;height: auto;display: flex;border-bottom: 1px dashed #EBECEC;">
                    <div style="width: 100%;height: 48px;margin: 0 auto;">
                        <div style="width: 120px;line-height: 48px;font-size: 14px;padding-left: 10px;">填写支付信息</div>
                    </div>
                </div>
                <div style="width:calc(100% - 20px); height: 130px;margin: 0 auto;overflow-x: auto;padding-top: 20px;">
                    <div style="width: 100%;height: 40px;display: flex;">
                        <div style="width: 450px;height: 40px;">
                            <div style="width: 100px;float: left;margin-top: 10px;font-size: 14px;"><span style="color: red">*</span>手机验证码</div>
                            <div style="width: 185px;float: left;height: 40px;margin-left: 5px">
                                <el-input name="code" v-model="code"  placeholder="短信验证码" > </el-input>
                            </div>
                            <div style="width: 100px;float: left;font-size: 10px;line-height: 25px;text-align: center;margin-left: 10px;">
                                <el-button  style="color: #FFFFFF;border: 1px solid #409EFF;background-color: #409EFF;width: 120px;padding: 12px 15px;" @click="getCode" :disabled="disabled">{{ codeString }}</el-button>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div style="width: 300px;height: 40px;display: flex;">
                                <div style="width: 80px;margin-top: 10px;font-size: 14px;"><span style="color: red">*</span>安全密码</div>
                                <div style="width: 185px;height: 40px;margin-left: 5px">
                                    <el-input type="password" autocomplete="new-password" name="code" v-model="payPwd"  placeholder="安全密码" > </el-input>
                                </div>
                            </div>
                            <div style="width: 300px;height: 40px;font-size: 10px;color: #26C6FD;display: flex;">
                                <div style="width: 100px;line-height: 40px;">
                                    <el-link type="primary">什么是安全密码</el-link>
                                </div>
                                <div style="width: 90px;line-height: 40px;padding-left: 10px;">
                                    <el-link type="danger" @click='b("/user/index/2")'>忘记密码？</el-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--未查到订单信息提示-->
        <div v-if="inPayment == -2" style="width: 100%;height: 800px;">
            <div style="width: 80%;height:500px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;">
                    <div style="width: 128px;height: 128px;margin: 0 auto;padding-top:80px;">
                        <img style="width: 128px;height: 128px;" src="@/assets/imgs/other/ddcu.png">
                    </div>
                    <div style="width: 168px;height: 50px;margin: 0 auto;text-align: center;font-size:20px;font-weight:bold;padding-top:20px;">
                        {{inPaymentMsg}}
                    </div>
                </div>
                <div style="width: 350px;height: 50px;margin: 0 auto;display: flex;color: #B4B4B4;font-size: 14px;">
                    <div style="width: 100%;">
                        <div @click="a('/')" @mouseover="bankIndexHover = true" @mouseleave="bankIndexHover = false" :style="bankIndexHover == true ? 'color: #1a1a1a':''"  style="width: 50%;line-height:35px;margin: 0 auto;text-align: center;border: 1px solid #c1c1c1;border-radius: 12px;cursor: pointer;">返回首页</div>
                    </div>
                </div>
            </div>
        </div>

        <!--支付已成功提示-->
        <div v-if="inPayment == -3" style="width: 100%;height: 800px;">
            <div style="width: 80%;height:500px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;">
                    <div style="width: 128px;height: 128px;margin: 0 auto;padding-top:80px;">
                        <img style="width: 128px;height: 128px;" src="@/assets/imgs/other/ddyzf.png">
                    </div>
                    <div style="width: 168px;height: 50px;margin: 0 auto;text-align: center;font-size:20px;font-weight:bold;padding-top:20px;">
                        {{inPaymentMsg}}
                    </div>
                </div>
                <div style="width: 350px;height: 50px;margin: 0 auto;display: flex;color: #B4B4B4;font-size: 14px;">
                    <div style="width: 50%;">
                        <div @click="a('/')" @mouseover="bankIndexHover = true" @mouseleave="bankIndexHover = false" :style="bankIndexHover == true ? 'color: #1a1a1a':''"  style="width: 50%;line-height:35px;margin: 0 auto;text-align: center;border: 1px solid #c1c1c1;border-radius: 12px;cursor: pointer;">返回首页</div>
                    </div>
                    <div style="width: 50%;">
                        <div @click="a('/user/index/11')" @mouseover="orderDetailsHover = true" @mouseleave="orderDetailsHover = false" :style="orderDetailsHover == true ? 'color: #1a1a1a':''" style="width: 50%;line-height:35px;margin: 0 auto;text-align: center;border: 1px solid #c1c1c1;border-radius: 12px;cursor: pointer;">查看订单</div>
                    </div>
                </div>
            </div>
        </div>

        <!--支付成功提示-->
        <div v-if="inPayment == 1" style="width: 100%;height: 800px;">
            <div style="width: 80%;height:500px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;">
                    <div style="width: 128px;height: 128px;margin: 0 auto;padding-top:80px;">
                        <img style="width: 128px;height: 128px;" src="@/assets/imgs/other/zfcg.png">
                    </div>
                    <div style="width: 128px;height: 50px;margin: 0 auto;text-align: center;font-size:20px;font-weight:bold;padding-top:20px;">
                       {{inPaymentMsg}}
                    </div>
                </div>
                <div style="width: 350px;height: 50px;margin: 0 auto;display: flex;color: #B4B4B4;font-size: 14px;">
                    <div style="width: 50%;">
                        <div @click="a('/')" @mouseover="bankIndexHover = true" @mouseleave="bankIndexHover = false" :style="bankIndexHover == true ? 'color: #1a1a1a':''"  style="width: 50%;line-height:35px;margin: 0 auto;text-align: center;border: 1px solid #c1c1c1;border-radius: 12px;cursor: pointer;">返回首页</div>
                    </div>
                    <div style="width: 50%;">
                        <div @click="a('/user/index/11')" @mouseover="orderDetailsHover = true" @mouseleave="orderDetailsHover = false" :style="orderDetailsHover == true ? 'color: #1a1a1a':''" style="width: 50%;line-height:35px;margin: 0 auto;text-align: center;border: 1px solid #c1c1c1;border-radius: 12px;cursor: pointer;">查看订单</div>
                    </div>
                </div>
            </div>
        </div>

        <!--等待倒计时-->
        <div v-if="inPayment == 2" style="width: 100%;height: 800px;">
            <div style="width: 80%;height:500px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;height: 100%;">
                    <div style="width: 128px;height: 128px;margin: 0 auto;padding-top:158px;">
                        <count-down></count-down>
                    </div>
                    <div style="width: 128px;line-height: 80px;margin: 0 auto;text-align: center;font-size:20px;font-weight:bold;">
                        {{inPaymentMsg}}
                    </div>
                </div>
            </div>
        </div>

        <!--支付按钮-->
        <div v-if="inPayment == 0 && paramsSatus == 1" style="width: 100%;height: 60px;background: #FFFFFF;position: fixed;bottom: 0;">
            <div style="width: 450px;height: 60px;float: right;margin-right:  20px;display: flex;">
                <div  style="width:300px;line-height: 60px;font-size: 14px;text-align: right;padding-right: 10px;">
                    实付金额
                    <span v-if="user.userType == 2" style="font-size: 12px;color: #a6a6a6;">(手续费:10.00元):</span>
                    <span style="color: #FFA133;font-weight: bold;font-size: 18px;">￥ {{payParams.payAmt}}</span>
                </div>
                <div @click="toPay" style="width:150px; height: 40px;background: #FFA133;text-align: center;line-height: 40px;color: #FFFFFF;margin-top: 10px;cursor: pointer;">
                    立即支付
                </div>
            </div>
        </div>

        <!-- 添加银行卡管理 -->
<!--        <el-dialog :show-close=false :visible.sync="isShowBandCard" v-if="isShowBandCard == true" :close-on-click-modal="false">-->
<!--            <addCard :onClose="function (){isShowBandCard = false}" :memberId="payParams.memberId" @change="getAdaPayBanCardList"></addCard>-->
<!--        </el-dialog>-->

        <!-- 添加对公账户管理 -->
        <el-dialog :show-close=false :visible.sync="isShowCorpCard" :close-on-click-modal="false">
            <addCorpCard :onClose="function (){isShowCorpCard = false}" @change="queryCorpAccount"></addCorpCard>
        </el-dialog>

        <!-- 对公支付 -->
        <organPayCode v-if="isShowOrgan == true" :payParams="payParams" @change="toPage($event)"></organPayCode>

        <!-- 微信支付 -->
        <el-dialog width="400px" title="" :custom-class="dialogCustomClass" :show-close=false :visible.sync="isShowWxpay" :close-on-click-modal="false">
            <wxPayCode  :orderType="payParams.orderType" :price="payParams.payAmt" :payParams="payParams.payParams" :onClose="function (){isShowWxpay = false}"></wxPayCode>
        </el-dialog>

        <!-- 支付宝支付 -->
        <aliPayCode v-if="isShowAlipay == true"  :orderType="payParams.orderType" :price="payParams.payAmt" :payParams="payParams.payParams"></aliPayCode>
    </div>
</template>

<script>

import addCorpCard from "@/components/common/Pay/organ/addCorpCard.vue";
import wxPayCode from "@/components/common/Pay/wxchat/wxPayCode.vue";
import aliPayCode from "@/components/common/Pay/aliPay/aliPayCode.vue";
import organPayCode from "@/components/common/Pay/organ/organPayCode.vue";
import countDown from "@/components/common/Pay/countDown.vue";
import VueQr from "vue-qr";
export default {
    components:{
        VueQr,
        addCorpCard,
        organPayCode,
        wxPayCode,
        aliPayCode,
        countDown,
    },
    computed:{
        user(){
            return this.$store.state.user;
        },
    },
    props:{
        //支付参数
        //支付类型1红包问答2悬赏资料3悬赏讨论4必选竞标5转账6专家咨询订单7报名事件8付费下载9专家审核订单10产品订单11服务订单12服务保证金13店铺保证金14知识仓库付费
        // payParams:{
        //     type:1,  //type = 1支付 2实时转账 3延时分账  必填
        //     memberId:"",  //商户下的用户id 唯一 必填
        //     payType:0,  //支付类型1红包问答2悬赏资料3悬赏讨论4必选竞标5转账 type = 2 || 3时必填
        //     orderType:0, //订单类型1购买会员2续费会员3购买空间4续费空间 type = 1 时必填
        //     payAmt:0,  //交易金额，必须大于0 必填
        //     orderNo:"",  //订单号 type = 2 || type = 3 时(payType = 5除外) 必填
        //     collectionUuid:"",  //收款方的uuid type = 3 时 必填
        // },
    },
    data() {
        return {
            inPayment:-100,  //支付状态 -4预支付订单类型错误 -3 订单已支付 -2订单信息错误 -1支付失败 0进行中 1支付成功 2等待倒计时
            inPaymentMsg:"",  //支付状态信息
            paramsSatus:1, //支付参数状态 1正常 0异常
            bankIndexHover: false,
            orderDetailsHover: false,
            activeName: 'platform',
            radio:'1',
            codeString: '获取验证码',
            form:false,
            promiseArr: [],
            dialogCustomClass: "my-dialog", // 设置自定义类名
            orderNo:'',
            payParams:{},
            productName:{},
            payChannel:1,
            sign:this.$route.query.sign,
            isSendSms:0,
            //个人
            platformRadio: 0,
            cardList:[],
            code:"",
            time: 60,
            disabled:false,
            addCardHover: false,
            isShowBandCard:false,
            payPwd:'',
            //机构
            corporateRadio:0,
            corpCardInfo:null,
            isShowCorpCard:false,
            addCorpCardHover: false,
            isShowOrgan:false,
            //微信
            wxRadio:'1',
            isShowWxpay:false,
            //支付宝
            aliRadio:'1',
            isShowAlipay:false,
            qrImgUrl:"https://devmobile.baigongbao.com/scanPayCode",
        };
    },
    created() {

    },

    mounted() {
        var that = this;
        this.getAdaPayBanCardList();
        this.queryCorpAccount();
        //获取预支付订单
        this.getPrePayOrder();

        // 先销毁事件，防止重复触发
        this.$EventBus.$EventBus.$off("payMsg");
        //支付监听
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                that.closeLoading();
                if (data.code == 100) {
                    if(this.activeName == 'platform'){
                        that.time = 0;
                        that.code = "";
                        that.payPwd = '';
                    }else if(this.activeName == 'wechat'){
                        that.isShowWxpay = false;
                    }else if(this.activeName == 'alipay'){
                        that.isShowAlipay = false;
                    }else if(this.activeName == 'corporate'){
                        that.isShowAlipay = false;
                    }
                    that.inPayment = 1;
                    that.inPaymentMsg = '支付成功';
                    that.utils.sus('支付成功');
                }else{
                    that.utils.err(data.msg);
                    that.getPrePayOrder();
                }
            });
        });

        // 先销毁事件，防止重复触发
        this.$EventBus.$EventBus.$off("callbackNotice");
        //支付监听
        this.$EventBus.$on("callbackNotice", (data) => {
            this.$nextTick(() => {
                if (data.code == 101) {
                    that.utils.err(data.msg);
                    that.getPrePayOrder();
                }
            });
        })

    },
    methods: {
        //标签页切换
        handleClick(tab) {
            this.activeName = tab.name;
            if(tab.name === 'platform' && this.cardList.length > 0) {
                this.form = true;
            } else {
                this.form = false;
            }
            //记录支付渠道
            if(tab.name === 'platform' || tab.name === 'corporate') {
                this.payChannel = 1;
            }else if(tab.name === 'wechat') {
                this.payChannel = 2;
            }else if(tab.name === 'alipay') {
                this.payChannel = 3;
            }
        },

        //获取预支付订单
        getPrePayOrder(){
          var that = this;
            that.newApi.getPrePayOrder({sign:that.sign}).then((res)=>{
                if(res.isSuccess == 1){
                    that.payParams = res.data.payParams;
                    that.orderNo = that.payParams.orderNo;
                    that.checkPayParams();
                    that.inPayment = 0;
                    that.inPaymentMsg = "";
                }else{
                    that.inPayment = res.data.code;
                    that.inPaymentMsg = res.data.msg;
                }
            })
        },

        //重新赋值预支付订单 转账类型时且 that.payParams.memberId = "" 时候会触发此方法 在addcard组件调用
        setPayParams(params){
            var that = this;
            that.payParams = params;
            that.orderNo = that.payParams.orderNo;
            that.inPayment = 0;
        },

        //检查支付参数
        checkPayParams(){
            var that = this;
            //安全密码
            if(that.user.bgbPayPwd == 0){
                that.utils.alert("请先设置安全密码",function () {
                    that.b("/user/index/2");
                });
                that.paramsSatus = 0;
                return false;
            }

            if(parseInt(that.payParams.type) == 0){
                that.utils.err("类型错误");
                that.paramsSatus = 0;
                return false;
            }

            if(that.payParams.payAmt == "" || that.payParams.payAmt < 0.01){
                that.utils.err("金额不能低于0.01");
                that.paramsSatus = 0;
                return false;
            }

            //支付
            if(parseInt(that.payParams.type) == 1){
                if(that.payParams.orderType == ""){
                    that.utils.err("支付类型参数错误");
                    that.paramsSatus = 0;
                    return false;
                }
            }

            //实时转账
            if(parseInt(that.payParams.type) == 2){
                if(that.payParams.collectionUuid == null || that.payParams.collectionUuid == ""){
                    that.utils.err("collectionUuid参数错误");
                    that.paramsSatus = 0;
                    return false;
                }
            }

            //延时分账
            if(parseInt(that.payParams.type) == 3){
                let arr = [1, 2, 3, 4, 5, 10,12,13];
                if (arr.includes(parseInt(that.payParams.payType))) {
                    if(that.payParams.orderNo == ""){
                        that.utils.err("延时分账类型参数错误");
                        that.paramsSatus = 0;
                        return false;
                    }
                } else {
                    if(that.payParams.orderNo == "" || that.payParams.collectionUuid == ""){
                        that.utils.err("实时分账类型参数错误");
                        that.paramsSatus = 0;
                        return false;
                    }
                }
            }
            that.getPayProductName();
        },

        //获取支付产品名
        getPayProductName() {
            var that = this;
            var params = {};
            params.type = that.payParams.type;
            params.orderType = that.payParams.orderType;
            params.payType = that.payParams.payType;
            that.newApi.getPayProductName(params).then((res) => {
                if(res.isSuccess == 1){
                    that.productName = res.data;
                }
            })
        },

        //点击获取验证码
        getCode(){
            var that = this;
            var obj = {};
            obj.payAmt = that.utils.keepTwoDecimalFull(that.payParams.payAmt);
            obj.payType = that.payParams.payType;
            obj.tokenNo = that.cardList[that.platformRadio].tokenNo;
            if( obj.tokenNo == ''){
                this.$message.error("请绑定银行卡")
                return;
            }
            that.openLoading("获取中...");
            if(parseInt(that.payParams.type) == 1){
                obj.payType = that.payParams.orderType;
                obj.sign = that.sign;
                that.alpplyBankCardPay(obj);
            }else if(parseInt(that.payParams.type) == 2){
                obj.memberId = that.payParams.memberId;
                obj.orderNo = that.payParams.orderNo;
                obj.collectionUuid = that.payParams.collectionUuid;
                obj.remark = that.payParams.remark;
                obj.sign = that.sign;
                that.settleAccountTransfer(obj);
            }else if(parseInt(that.payParams.type) == 3){
                obj.orderNo = that.payParams.orderNo;
                obj.collectionUuid = that.payParams.collectionUuid;
                obj.sign = that.sign;
                that.settleAccountPenny(obj);
            }

        },

        //获取验证码倒计时
        countdown(){
            var that = this;
            if(that.time == 0) {
                that.disabled = false;
                that.codeString ='获取验证码';
                that.time  = 60;
                that.isSendSms = 1;
                return;
            } else {
                that.disabled = true;
                that.codeString = that.time + '秒重新发送';
                that.time --;
            }
            setTimeout(function(){that.countdown();}, 1000);
        },

        // 查询快捷卡对象
        getAdaPayBanCardList(){
            var that = this;
            that.newApi.getAdaPayBanCardList({}).then((res)=>{
                if(res.isSuccess == 1){
                    that.cardList = res.data;
                    if(that.activeName == 'platform' && res.data.length > 0){
                        that.form = true;
                    }else{
                        that.form = false;
                    }
                }
            })
        },

        //查询对公账户
        queryCorpAccount(){
            var that = this;
            that.newApi.queryCorpAccount({}).then((res)=>{
                if(res.isSuccess == 1){
                    if (res.data != null){
                        that.corpCardInfo = res.data;
                        that.corporateRadio = res.data.id;
                    }
                }
            })
        },

        //跳转对公支付页面
        toPage(data){
            var that = this;
            //保存修改的预支付订单信息
            var params = that.payParams;
            params.orderNo = data.orderNo;
            params.activeName = 'corporate';
            that.newApi.addAndSavePrePayOrder({paymentType:2,type:2,toPay:JSON.stringify(params),sign:that.sign}).then(() => {
                this.utils.b(data.url);
            })

        },

        //创建支付对象申请 返回订单
        alpplyBankCardPay(params) {
            var that = this;
            that.newApi.alpplyBankCardPay(params).then((res) => {
                if(res.isSuccess == 1){
                    that.orderNo = res.data;
                    that.payParams.orderNo = res.data;
                    that.utils.sus("请注意查收验证码");
                    that.countdown();
                }
            })
        },
        //创建实时转账对象申请 返回订单
        settleAccountTransfer(params) {
            var that = this;
            that.newApi.settleAccountTransfer(params).then((res) => {
                if(res.isSuccess == 1){
                    that.orderNo = res.data;
                    that.payParams.orderNo = res.data;
                    that.utils.sus("请注意查收验证码");
                    that.countdown();
                }
            })
        },
        //创建分账转账对象申请 返回订单
        settleAccountPenny(params) {
            var that = this;
            that.newApi.settleAccountPenny(params).then((res) => {
                if(res.isSuccess == 1){
                    that.orderNo = res.data;
                    that.payParams.orderNo = res.data;
                    that.utils.sus("请注意查收验证码");
                    that.countdown();
                }
            })
        },

        //立即支付
        toPay(){
            var that = this;
            let params = {};
                if(that.activeName == 'wechat') {
                    this.isShowWxpay = true;
                }else if(that.activeName == "alipay") {
                    this.isShowAlipay = true;
                }else if (that.activeName == 'corporate') {
                    if(that.corpCardInfo == null){
                        that.utils.msg("请先绑定对公账户");
                        return false;
                    }
                    that.isShowOrgan = true;
                    that.inPayment = 2;
                    that.inPaymentMsg = '支付中...';
            }else{
                    if(that.cardList.length == 0){
                        that.utils.msg("请先绑定银行卡");
                        return false;
                    }
                    if(that.code == ""){
                        that.utils.err("请填写手机验证码");
                        return false;
                    }
                    if(that.payPwd == ""){
                        that.utils.err("请填写安全密码");
                        return false;
                    }
                    params.code = that.code;
                    params.payPwd = that.payPwd;
                    params.orderNo = that.orderNo;
                    params.payType = parseInt(that.payParams.payType);
                    params.payChannel = that.payChannel;
                    params.activeName = that.activeName;
                    console.log(params);
                    //保存修改的预支付订单信息
                    that.newApi.addAndSavePrePayOrder({paymentType:1,type:2,toPay:JSON.stringify(params),sign:that.sign}).then(() => {
                        that.inPayment = 2;
                        that.inPaymentMsg = '支付中...';
                    })
                }
        }
    }
};
</script>

<style scoped>
.full-screen-div {
    width: 100%;
    height: auto;
    color: #333333
}
table {
    width: 100%;
    table-layout: fixed; /* 固定表格布局 */
    border-collapse: collapse; /* 合并边框 */
    border: 1px solid #D7D8D9; /* 设置表格边框 */
    font-size: 12px;
    text-indent: 12px;
}
table td {
    width: 20%; /* 平均分配每一列宽度 */
    height: 40px; /* 设置每一行的高度为40px */
}
/* 自定义对话框样式 */
/deep/.my-dialog .el-dialog__header {
  padding: 0px;
}

/deep/.my-dialog .el-dialog__body {
    padding: 10px;
}
</style>
